$color-content-bg: #fff;

$color-primary: #0062cc;
$color-secondary: #0062cc;

$color-text: #333;
$color-text-on: #fff;
$color-text-error: #d53232;
$color-text-disabled: #ccc;
$color-text-label: #566d77;
$color-text-sub: rgba(0, 0, 0, 0.54);

$color-message-border: #ddd;
$color-opponent-message: #ddd;

$color-field-border: #ddd;
$color-field-foreground: #757575;
$color-category-title-background: #f0f2f4;

$color-table-head-background: #f0f4f1;
$color-table-row-background: #fcfdfd;
$color-select-table-row-hover: #f4fcf6;
$color-select-table-row-selected: #e5f5e9;

$color-table-data-border: #f0f2f4;
$color-table-title-border: #fff;

$color-karte-title-l1: #d6d8e2;
$color-karte-title-l2: #e6e8ef;
$color-karte-title-l3: #f0f2f4;

$color-selected-row: #cfecea;

$gradient-primary: linear-gradient(135deg, #0062cc, #0062cc);
