@import "mixins/font-size";
@import "variables/color";

.c-page-header {
  padding-top: 30px;
}

.c-page-header__breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0 0 6px;
  > li {
    @include font-size(12px);
    display: inline-block;
    &::before {
      content: "/";
      margin: 0 0.5em;
    }
    > a {
      color: $color-text-label;
    }
  }
}
.c-page-header__title {
  @include font-size(20px);
  // color: $color-text-label;
  color: black;
  margin-top: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 1em;
  &:first-child {
    margin-top: 26px;
  }
}

.c-page-header__title_middle {
  @include font-size(16px);
  color: $color-text-label;
  margin-top: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 1em;
  &:first-child {
    margin-top: 26px;
  }
}
