@import 'mixins/font-size';
@import 'variables/color';

/**
 * 入力項目フィールドのスタイルを指定する
 */

.c-input-field {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0;
  > .mat-radio-group {
    font-size: 1rem;
    margin-top: 8px;
    display: block;
  }
}
.c-field-label {
  // position: absolute;
  // top: -13px;
  left: 5px;
  padding: 0 10px;
  background: $color-content-bg;
  border-radius: 4px;
  font-weight: bolder;
}
.c-field-label,
.c-field-hint {
  font-size: 1rem;
  color: $color-text;
  margin-left: 2px;
  display: inline-block;
  min-height: 1.6em;
}
.c-field-hint {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-field-hint--error {
  color: $color-text-error;
}

input:not([type='checkbox']):not([type='radio']):not([type='file']),
textarea,
.c-input-field__file-input {
  border-radius: 4px;
  border: 1px solid $color-field-border;
  @include font-size(13px);
  padding: 7px 12px 5px;
  box-sizing: border-box;
  color: $color-text;
  outline-color: $color-primary;
  transition-duration: 0.3s;
  outline-offset: -4px;
}
select {
  border-radius: 4px;
  border: 1px solid $color-field-border;
  @include font-size(13px);
  height: 30px;
  padding: 0 10px;
  width: 50%;
  box-sizing: border-box;
  background-color: transparent;
  text-indent: 2px;
  color: $color-text;
  outline-color: $color-primary;
  transition-duration: 0.3s;
  outline-offset: -4px;
  align-items: center;
}
.c-input-field {
  > mat-list,
  > mat-selection-list {
    border-radius: 4px;
    border: 1px solid $color-field-border;
  }
}

input:not([type='checkbox']):not([type='radio']):not([type='file']).date-input {
  padding-left: 50px;
  outline: none;
}

input[type='file'] {
  position: absolute;
  width: 100%;
  height: calc(100% - 16px);
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  & + .c-input-field__file-input {
    position: relative;
    height: 2.4em;
    outline-color: $color-primary;
    transition-duration: 0.3s;
    outline-offset: -4px;
    > span {
      width: calc(100% - 70px);
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &::after {
      content: 'ファイルを選択';
      font-size: 10px;
      display: flex;
      border-left: 1px solid $color-field-border;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding: 0 4px;
      align-items: center;
      cursor: pointer;
    }
  }
  &:focus + .c-input-field__file-input {
    outline-style: auto;
  }
}

input:not([type='checkbox']):not([type='radio']):not([type='file']):required,
textarea:required,
select:required,
mat-selection-list[required],
input[type='file']:required + .c-input-field__file-input {
  + .c-field-label {
    &::after {
      @include font-size(10px);
      content: '必須';
      color: $color-text-error;
      margin-left: 0.2em;
    }
  }
}

.c-input-field--readonly {
  input:not([type='checkbox']):not([type='radio']):not([type='file']),
  select,
  textarea {
    &:focus {
      outline: none;
    }
    &::selection {
      background: none;
    }
  }
  input[type='file']:focus + .c-input-field__file-input {
    outline: none;
  }
}
.c-input-field--disabled {
  .c-field-label,
  .c-field-hint {
    color: $color-text-disabled;
  }
  input:not([type='checkbox']):not([type='radio']):not([type='file']),
  select,
  textarea {
    border-color: $color-text-disabled;
    color: $color-text-disabled;
    &:focus {
      outline: none;
    }
  }
  input[type='file']:focus + .c-input-field__file-input {
    border-color: $color-text-disabled;
    color: $color-text-disabled;
    outline: none;
  }
}

.c-input-field--error:not(.c-input-field--disabled) {
  .c-field-label,
  .c-field-hint {
    color: $color-text-error;
  }
  input:not([type='checkbox']):not([type='radio']):not([type='file']),
  select,
  textarea,
  mat-selection-list,
  input[type='file'] + .c-input-field__file-input {
    border-color: $color-text-error;
    color: $color-text-error;
    &:focus {
      outline-color: $color-text-error;
    }
  }
  input[type='file']:focus + .c-input-field__file-input {
    outline-color: $color-text-error;
  }
}

.c-input-field__actions {
  display: block;
  text-align: right;
  > .mat-button,
  > .mat-flat-button {
    margin-left: 10px;
  }
}
