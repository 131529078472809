@import '~@angular/material/theming';

/**
 * @angular/material のテーマスタイル設定
 */

@include mat-core();

$app-primary: mat-palette($mat-green);
$app-accent: mat-palette($mat-light-green);
$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
@include angular-material-theme($app-theme);
