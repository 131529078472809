@import './mixins/font-size';
@import 'variables/color';

/**
 * @angular/material のスタイルを改変する場合、ここに記述する
 */

button,
a {
  &.mat-button,
  &.mat-raised-button,
  &.mat-icon-button,
  &.mat-stroked-button,
  &.mat-flat-button,
  &.mat-fab,
  &.mat-mini-fab {
    @include font-size(13px);
    border-radius: 10px;
    line-height: 30px;
  }
}

.mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-dialog-content {
  white-space: pre-wrap;
}
.mat-dialog-actions {
  justify-content: flex-end;
}
.mat-dialog-container {
  min-width: 300px;
}

.c-input-field .mat-list-base .mat-list-item.mat-list-option,
.c-input-field .mat-list-base .mat-list-item {
  height: 2.2em;
  font-size: 13px;
}
.c-input-field .mat-list-base.mat-selection-list,
.c-input-field .mat-list-base.mat-list {
  max-height: 200px;
  overflow-y: auto;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 4em;
}
