@import "../variables/color";
@import "../variables/font";

/**
 * ベースのタグスタイルを指定する
 * クラス属性でのスタイル指定はここには書かない
 */

// * {
  // 全ての要素のテキスト選択を制限する
  // user-select: none;
// }

html {
  font-size: $font_base_size;
  font-family: $font-family;
  color: $color-text;
  height: 100%;
  min-width: 0px;
}

body {
  margin: 0;
  height: 100%;
}

input, textarea {
  // Safariでは入力不可になってしまうので、全要素に指定しているテキスト選択の制限を解除
  user-select: initial;
}

textarea {
  resize: vertical;
}

a:hover,
a:focus {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
a:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
#zmmtg-root {
  display:none;
}
