/**
 * ボタンのスタイルを指定する
 */

.c-button__primary {
  &:not([disabled]) {
    background: $gradient-primary;
    color: $color-text-on;
  }
}
.c-anchor-button {
  background: none;
  border: none;
  text-decoration: underline;
  &::-moz-focus-inner {
    border: 0;
  }
  outline-style: none;
  cursor: pointer;
  padding: 0 0.25em;
  color: inherit;
}
.c-button__clear {
  &:not([disabled]) {
    background: grey;
    color: $color-text-on;
  }
}
