.p-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.p-form__category {
  display: flex;
  align-items: flex-end;
}
.p-form__category-label {
  display: inline-flex;
  align-self: flex-start;
  margin: 15px 20px 0 0;
  width: 140px;
}
.p-form__fields {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.p-form__field-group {
  display: flex;
  align-items: center;
  > * {
    margin-right: 20px;
  }
}
.p-form__field-datetime {
  display: flex;
  width: 400px;
}
.p-form__field {
  width: 260px;
  margin-right: 20px;
}
.p-form__field--large {
  width: 400px;
  margin-right: 20px;
}
.p-form__actions {
  display: inline-flex;
  flex-direction: column;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  min-width: 120px;
  margin-left: 160px;
  align-self: flex-start;
}
.p-form__error-msg {
  font-size: 11px;
  color: $color-text-error;
  min-height: 1.5em;
  text-align: center;
}

.p-form-inline {
  display: flex;
  align-items: baseline;
  > * {
    margin-right: 20px;
  }
}
.p-form-inline__category-label {
  text-align: right;
  display: inline-block;
  width: 140px;
}
.p-form-inline__field {
  display: inline-block;
  width: 170px;
  margin-bottom: 0;
}
.p-form-inline__actions {
  display: inline-flex;
  flex-direction: column;
  min-width: 120px;
  margin-bottom: 16px;
}
