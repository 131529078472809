/* You can add global styles to this file, and also import other style files */
@import '~@aws-amplify/ui-angular/theme.css';

:root {
  --amplify-primary-color: #0062cc;
  --amplify-primary-tint: #0062cc;
  --amplify-primary-shade: #0062cc;
}

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css');

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300&subset=japanese');

@import 'styles/mat-theme';
@import 'styles/mat-modified';

@import 'styles/1-foundation/base';

@import 'styles/3-1-component/input-field';
@import 'styles/3-1-component/button';
@import 'styles/3-1-component/page-header';

@import 'styles/3-2-project/form';

body {
  font-size: 0.9rem;
  min-height: 30vh;
}

[data-amplify-authenticator] {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.dataTables_wrapper div.dataTables_length select {
  min-width: 50px;
}
.page-wrapper__sidebar__hidden {
  // left: -300px !important;
  display: none;
}
.page-wrapper__sidebar__show {
  // left: -300px !important;
  display: none;
}

// angular perfect scrollbar style
.sidebar .ps.ps--scrolling-y > .ps__rail-y,
.sidebar > .ps.ps--scrolling-x > .ps__rail-x,
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
}

.sidebar .ps.ps--active-y > .ps__rail-y:hover,
.sidebar > .ps.ps--active-y > .ps__rail-y.ps--clicking {
  width: 10px !important;
}

.ps__thumb-y {
  background-color: #fff !important;
  width: 6px !important;
  border-radius: 0px !important;
  opacity: 0.5;
}
.no-data-available {
  text-align: center;
}
th {
  white-space: nowrap;
}
a {
  cursor: pointer;
}
table.dataTable td {
  background: #eee;
}
table.dataTable tr:nth-child(odd) td {
  background: #fff;
}
.dataTables_wrapper {
  width: calc(100% - 20px);
}
.dataTables_wrapper .dataTables_length select {
  width: auto;
}
.dataTables_wrapper .dataTables_filter input {
  width: auto !important;
}
.card-header {
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
  background-color: transparent;
  padding: 0px;
}
.card {
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
}
.card-body {
  padding: 0.5rem;
}

.ngb-dp-day {
  font-size: 2rem;
  width: 7rem !important;
  height: 6rem !important;
}
.ngb-dp-day > span {
  font-size: 2rem;
  width: 7rem !important;
  height: 6rem !important;
  line-height: 6rem !important;
}
.ngb-dp-weekday {
  font-size: 2rem;
  width: 7rem !important;
  height: 6rem !important;
}

.ngb-dp-header {
  padding-bottom: 2rem;
}

.page-item.active .page-link {
  background-color: #027e68 !important;
  border-color: #027e68 !important;
}
.page-link {
  color: #027e68;
}
.pagination {
  --bs-pagination-hover-color: #027e68;
}

.mat-datepicker-toggle button {
  width: 30px;
  height: 30px;
}
